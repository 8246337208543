import React from "react";
import EventIcon from "@material-ui/icons/Event";
import Button from "@material-ui/core/Button";
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

export default function AddToCalendarButton(props) {
  const { event, className, size } = props;

  const handleAddToCalendar = () => {
    // Convert event start time to a date object (UTC)
  const startAtUTC = new Date(event.start_at);
  
  // Seattle time zone adjustment (America/Los_Angeles)
  const startAtSeattle = new Date(startAtUTC.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));

  // Retrieve order window start and end times from the event (in UTC format)
  const orderWindowStartAt = event.order_window_start_at; // e.g., "01:00:20" (UTC)
  const orderWindowEndAt = event.order_window_end_at; // e.g., "03:00:20" (UTC)

  // Convert order window start time (UTC) to Seattle time
  const [startHourUTC, startMinuteUTC, startSecondUTC] = orderWindowStartAt.split(':');
  const startAtUTCConverted = new Date(Date.UTC(
    startAtSeattle.getFullYear(), 
    startAtSeattle.getMonth(), 
    startAtSeattle.getDate(),
    parseInt(startHourUTC), 
    parseInt(startMinuteUTC),
    parseInt(startSecondUTC)
  ));
  
  // Adjust the time to Seattle time zone
  const startAtSeattleConverted = new Date(startAtUTCConverted.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));

  // Convert order window end time (UTC) to Seattle time
  const [endHourUTC, endMinuteUTC, endSecondUTC] = orderWindowEndAt.split(':');
  const endAtUTCConverted = new Date(Date.UTC(
    startAtSeattle.getFullYear(), 
    startAtSeattle.getMonth(), 
    startAtSeattle.getDate(),
    parseInt(endHourUTC), 
    parseInt(endMinuteUTC),
    parseInt(endSecondUTC)
  ));
  
  // Adjust the time to Seattle time zone
  const endAtSeattleConverted = new Date(endAtUTCConverted.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));

  // Format the start and end dates to match the required format (YYYY-MM-DDTHH:mm:ss)
  const formatDate = (date) => {
    const year = startAtSeattle.getFullYear();
    const month = String(startAtSeattle.getMonth() + 1).padStart(2, '0');
    const day = String(startAtSeattle.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  };

  const startStr = formatDate(startAtSeattleConverted);  // Formatted start date (YYYY-MM-DDTHH:mm:ss)
  const endStr = formatDate(endAtSeattleConverted);    // Formatted end date (YYYY-MM-DDTHH:mm:ss)

  console.log("Start Date: " + startStr);
  console.log("End Date: " + endStr);
    const calendarEvent = {
      name: "Piroshky Piroshky Pick up " + (event.name || ""),
      description: event.description || "",
      startDate: startStr.split("T")[0], // Extract just the date portion
      endDate: endStr.split("T")[0], // Extract just the date portion
      location: (event.address || "") + ", " + (event.city || "") + ", " + (event.state || "") + ", " + (event.postal_code || ""),
      options: [
        "Apple",
        "Google",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
        "Yahoo",
        "iCal"
      ],
      //timeZone: undefined,//Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : undefined,
      inline: true,
      listStyle: "modal",
      trigger: "click",
      iCalFileName: "Piroshky-Event"
    };

    // Trigger the calendar event action
    atcb_action(calendarEvent);
  };

  return (
    <Button aria-label="Add Event To Your Personal Calendar" className={className || "button-secondary"} size={size} variant="outlined" onClick={handleAddToCalendar}>
      <EventIcon fontSize={size === "large" ? "medium" : size} className="u-textColorNormal u-marginRight8" /> Add to Calendar
    </Button>
  );
}